/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar anticipo</h1>

    <h3 class="form-division">Información de cuenta</h3>

    <b-checkbox
      v-model="newPayment.requireCDFI"
      class="special-margin"
      v-if="!objectInformation && type === 1 && checkIfCanInvoiceCompany()"
    >
      Timbrar anticipo
    </b-checkbox>

    <b-field grouped class="special-margin" v-if="type === 0">
      <b-field
        label="Proveedor (Requerido)"
        expanded
        label-position="inside"
        required
        class="required-input"
        :type="{ 'is-danger': newPaymentErrors.CLAVE_PRV }"
        :message="{
          'El proveedor no es valido': newPaymentErrors.CLAVE_PRV,
        }"
      >
        <b-autocomplete
          placeholder="Proveedor"
          :data="autoCompleteProviders(provName)"
          v-model="provName"
          field="NOMBRE_PRV"
          autocomplete
          icon="credit-card-outline"
          open-on-focus
          expanded
          clearable
          @select="
            (option) => {
              newPayment.CLAVE_PRV = option._id;
            }
          "
          check-infinite-scroll
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
        >
          <template #empty>No se encontraron proveedores</template>
        </b-autocomplete>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="type === 1">
      <b-field label-position="inside" label="Vendedor (Opcional)" expanded>
        <b-select
          placeholder="Ejemplo: Sin vendedor"
          icon="account-tie"
          expanded
          v-model="newPayment.VENDEDOR"
        >
          <option :value="undefined">Sin vendedor</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in employees"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="type === 1">
      <b-field
        label="Cliente (Requerido)"
        expanded
        label-position="inside"
        required
        class="required-input"
      >
        <b-autocomplete
          placeholder="Cliente"
          :data="autoCompleteClient(clientName)"
          v-model="clientName"
          field="NOMBRE_EMPRESA"
          autocomplete
          icon="credit-card-outline"
          open-on-focus
          expanded
          clearable
          @select="
            (option) => {
              newPayment.CLAVE_CLIENTE = option._id;
              setAccountInformation();
            }
          "
          check-infinite-scroll
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
        >
          <template #empty>No se encontraron proveedores</template>
        </b-autocomplete>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Cuenta bancaria (Requerido)"
        expanded
        label-position="inside"
        required
        class="required-input"
        :type="{ 'is-danger': newPaymentErrors.CUENTA }"
        :message="{
          'La cuenta bancaria del pago no es valida': newPaymentErrors.CUENTA,
        }"
      >
        <b-select
          placeholder="Ejemplo: Cuenta de gastos"
          expanded
          icon="credit-card-outline"
          v-model="newPayment.CUENTA"
          @change.native="setAccountInformation()"
        >
          <option value="" disabled>Selecciona una cuenta</option>
          <option
            :value="singleAccount._id"
            v-for="singleAccount in bankAccounts"
            v-bind:key="singleAccount._id"
          >
            {{
              singleAccount.NOMBRE ? singleAccount.NOMBRE : singleAccount.CUENTA
            }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin invoice-article-div">
      <b-field
        label="Movimiento bancario a usar (Opcional)"
        expanded
        label-position="inside"
      >
        <b-autocomplete
          placeholder="Movimiento bancario"
          :data="autoCompleteMovement(movementName)"
          v-model="movementName"
          field="MOVIMIENTO_NOMBRE_FOR"
          autocomplete
          icon="credit-card-outline"
          open-on-focus
          expanded
          clearable
          @select="
            (option) => {
              if (option) {
                newPayment.CLAVE_MOVIMIENTO = option._id;
                handleMovementSelected();
              } else {
                newPayment.CLAVE_MOVIMIENTO = undefined;
                selectedAccountMovementBalance = 0;
              }
            }
          "
          check-infinite-scroll
        >
          <template #empty>No se encontraron movimientos</template>
        </b-autocomplete>
      </b-field>
      <b-button
        icon-right="magnify"
        size="is-small"
        type="is-primary"
        class="search-payment"
        @click="openSearchMovement()"
        v-if="newPayment.CUENTA && !objectInformation"
      >
      </b-button>
    </b-field>

    <b-field
      label="Disponible a usar en movimiento (Opcional)"
      expanded
      label-position="inside"
      v-if="type === 1 && newPayment.CLAVE_MOVIMIENTO && !hideMovement"
    >
      <MoneyInput
        currency="MXN"
        :digits="5"
        placeholder="Ejemplo: 16"
        icon="cash-100"
        v-model="selectedAccountMovementBalance"
        :required="true"
        :disabled="true"
      >
      </MoneyInput>
    </b-field>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Monto total con impuestos (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.PAGO_PARC }"
        :message="{
          'El monto del pago no es valido': newPaymentErrors.PAGO_PARC,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="newPayment.PAGO_PARC"
          :required="true"
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
        >
        </MoneyInput>
      </b-field>
      <b-field
        label="Porcentaje de IVA (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.POR_IVA }"
        :message="{
          'El porcentaje de IVA del pago no es valido':
            newPaymentErrors.POR_IVA,
        }"
        v-if="type === 1"
      >
        <PercentageInput
          placeholder="Ejemplo: 40"
          expanded
          icon="numeric"
          v-model="newPayment.POR_IVA"
          type="number"
          step="any"
          min="1"
          required
        />
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Forma de pago (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.FORMA_P }"
        :message="{
          'El tipo del pago no es valido': newPaymentErrors.FORMA_P,
        }"
      >
        <b-select
          placeholder="Ejemplo: Transferencia"
          expanded
          icon="credit-card-plus-outline"
          class="required-input"
          required
          v-model="newPayment.FORMA_P"
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
        >
          <option value="" disabled>Selecciones una forma de pago</option>
          <option
            :value="singlePaymentType._id"
            v-for="singlePaymentType in paymentTypes"
            v-bind:key="singlePaymentType._id"
          >
            {{ singlePaymentType.DESCRIBE_P }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número de cuenta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Martillo azul con mango de madera"
          expanded
          icon="card-account-details-outline"
          v-model="newPayment.NO_CUENTA"
        ></b-input>
      </b-field>
      <b-field
        label="CLABE interbancaria (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 000000000000000000"
          expanded
          icon="bank-outline"
          v-model="newPayment.NO_CLABE"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Número de tarjeta (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 0000000000000000"
          expanded
          icon="card-text-outline"
          v-model="newPayment.NO_TARJETA"
        ></b-input>
      </b-field>
      <b-field
        label="Número de cheque (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: 12398"
          expanded
          icon="card-bulleted-outline"
          v-model="newPayment.NO_CHEQUE"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de pago (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.FECHA_PAGO }"
        :message="{
          'La fecha del pago no es valida': newPaymentErrors.FECHA_PAGO,
        }"
        class="required-input"
      >
        <b-datetimepicker
          :datetime-formatter="
            (date) => moment(date).format('DD/MM/YYYY HH:mm:ss')
          "
          placeholder="Fecha de pago"
          icon="calendar-today"
          required
          v-model="newPayment.FECHA_PAGO"
          trap-focus
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
          :locale="locale"
          :datepicker="{ dayNames, monthNames, locale }"
          :timepicker="{ enableSeconds, hourFormat }"
          horizontal-time-picker
        >
        </b-datetimepicker>
      </b-field>
      <b-field
        label="Referencia (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.REFERENCIA }"
        :message="{
          'La referencia del pago no es valida': newPaymentErrors.REFERENCIA,
        }"
      >
        <b-input
          placeholder="Ejemplo: 12387BH"
          expanded
          icon="pencil-box"
          required
          v-model="newPayment.REFERENCIA"
          :disabled="
            objectInformation !== null && objectInformation !== undefined
          "
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pago realizado el día Martes"
          expanded
          v-model="newPayment.OBSERVACIONES"
          type="textarea"
        ></b-input>
      </b-field>
    </b-field>

    <!--
    <h3
      class="form-division"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Ajustes en cuenta bancaria
    </h3>

    <b-checkbox
      v-model="newPayment.createMovement"
      class="permissions-checkbox first-row-checkbox"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Crear movimiento en cuenta bancaria
    </b-checkbox>

    <b-checkbox
      v-model="newPayment.affectBalance"
      class="permissions-checkbox first-row-checkbox"
      v-if="!objectInformation && !newPayment.CLAVE_MOVIMIENTO"
    >
      Afectar saldo de cuenta bancaria
    </b-checkbox>
    -->

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="addNewPayment()"
        v-if="!objectInformation"
        >Crear anticipo sin cuenta por cobrar</b-button
      >

      <b-button
        type="is-success"
        v-if="objectInformation && !objectInformation.CLAVE_MOVIMIENTO"
        @click="editPayment()"
        >Editar anticipo</b-button
      >

      <b-button
        type="is-success"
        v-if="
          objectInformation &&
          objectInformation.STATUS_FAC === 'SF' &&
          objectInformation.STATUS !== 'CA' &&
          type !== 0
        "
        @click="savePaymentInvoice()"
        >Timbrar anticipo</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        :class="{
          'cancel-button':
            !objectInformation ||
            (objectInformation && objectInformation.STATUS_FAC === 'SF'),
        }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import SelectMovement from "@/modules/Treasury/components/SelectMovement.vue";
import { EventBus } from "@/event-bus";
export default {
  name: "AddNewAdvancedPaymentModal",
  props: ["objectInformation", "type"],
  components: {},
  data() {
    return {
      // Table values
      newPayment: {
        CUENTA: "",
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        NO_DOCUM: "",
        PAGO_PARC: "",
        POR_IVA: 16,
        FECHA_PAGO: new Date(new Date().setHours(0, 0, 0, 0)),
        TIPO_PAGOP: "A",
        REFERENCIA: "",
        FORMA_P: "",
        STATUS: "AC",
        CLAVE_MOVIMIENTO: undefined,
        VENDEDOR: undefined,
        OBSERVACIONES: "Anticipo del bien o servicio",
        createMovement: false,
        affectBalance: false,
        requireCDFI: false,
      },
      newPaymentErrors: {
        CUENTA: false,
        PAGO_PARC: false,
        POR_IVA: false,
        FORMA_P: false,
        FECHA_PAGO: false,
        REFERENCIA: false,
      },
      selectedAccountBalance: 0,
      selectedAccountMovements: [],
      selectedAccountMovementBalance: 0,
      moment: moment,
      provName: "",
      clientName: "",
      movementName: "",
      hideMovement: false,
      enableSeconds: true,
      hourFormat: "24",
      locale: "es-MX",
      dayNames: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      advancedPaymentUploading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Add client or provider information according to the one needed
    if (this.type === 0) {
      this.newPaymentErrors.CLAVE_PRV = false;
      this.newPayment.OBSERVACIONES = "";
      this.newPayment.CLAVE_PRV = "";
    }

    if (this.type === 1) {
      this.newPaymentErrors.CLAVE_CLIENTE = false;
      this.newPayment.CLAVE_CLIENTE = "";

      if (this.checkIfCanInvoiceCompany()) {
        this.newPayment.requireCDFI = true;
      }
    }

    if (this.objectInformation) {
      // Fill the form with received information
      fillObjectForm(this.objectInformation, this.newPayment);
      this.newPayment._id = this.objectInformation._id;
      this.newPayment.FORMA_P = this.objectInformation.FORMA_P._id;
      this.newPayment.FECHA_PAGO = new Date(this.objectInformation.FECHA_PAGO);

      if (this.type === 0) {
        this.newPayment.CLAVE_PRV = this.objectInformation.CLAVE_PRV._id;
        this.provName = this.objectInformation.CLAVE_PRV.NOMBRE_PRV;
      }

      if (this.type === 1) {
        this.newPayment.CLAVE_CLIENTE =
          this.objectInformation.CLAVE_CLIENTE._id;
        this.clientName = this.objectInformation.CLAVE_CLIENTE.NOMBRE_EMPRESA;
      }

      if (this.objectInformation.CLAVE_MOVIMIENTO) {
        this.movementName = `${
          this.objectInformation.CLAVE_MOVIMIENTO.REFERENCIA
        } ${this.formatMoneyString(
          this.objectInformation.CLAVE_MOVIMIENTO.IMPORTE
        )}`;
        this.newPayment.CLAVE_MOVIMIENTO =
          this.objectInformation.CLAVE_MOVIMIENTO._id;
        this.hideMovement = true;
      }

      if (this.objectInformation.VENDEDOR) {
        this.newPayment.VENDEDOR = this.objectInformation.VENDEDOR._id;
      }

      this.setAccountInformation();
    }

    EventBus.$on("searchMovementSelected", (selection) => {
      this.movementName = selection.movement.REFERENCIA;
      this.newPayment.CLAVE_MOVIMIENTO = selection.movement._id;
      this.handleMovementSelected();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async addNewPayment() {
      try {
        if (this.advancedPaymentUploading) {
          return;
        }

        if (!this.validateBankMovement()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el saldo disponible en el movimiento bancario seleccionado`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        this.articleRequiredErrors = validateFields(
          this.newPayment,
          this.newPaymentErrors
        );

        if (checkValidationErrors(this.newPaymentErrors)) {
          this.advancedPaymentUploading = true;

          let response = await this.$store.dispatch(
            "ADDADVANCEDPAYMENT",
            this.newPayment
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el anticipo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el anticipo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }

          this.advancedPaymentUploading = false;
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el anticipo en la base de datos revise los campos requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.advancedPaymentUploading = false;

        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el anticipo la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async editPayment() {
      try {
        if (!this.validateBankMovement()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el saldo disponible en el movimiento bancario seleccionado`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        this.articleRequiredErrors = validateFields(
          this.newPayment,
          this.newPaymentErrors
        );

        if (checkValidationErrors(this.newPaymentErrors)) {
          let response = await this.$store.dispatch(
            "EDITADVANCEDPAYMENT",
            this.newPayment
          );

          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se actualizó exitosamente el anticipo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al actualizar el anticipo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al actualizar el anticipo en la base de datos revise los campos requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al actualizar el anticipo la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async savePaymentInvoice() {
      try {
        let response = await this.$store.dispatch(
          "SAVEINVOICEADVANCEDPAYMENT",
          {
            paymentId: this.objectInformation._id,
          }
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó el timbrado exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al timbrar:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al timbrar en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    autoCompleteClient(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients.filter((singleClient) => {
        return (
          singleClient.CLAVE_CLI.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singleClient.NOMBRE_EMPRESA.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    /**
     *
     * @desc sets the balance of the current selected bank account
     * @return { number } the balance on the selected bank account
     */
    setAccountInformation() {
      for (const singleBankAccount of this.bankAccounts) {
        if (this.newPayment.CUENTA === singleBankAccount._id) {
          if (this.type === 1) {
            this.selectedAccountMovements = singleBankAccount.MOVIMIENTOS.map(
              (singleMovement) => {
                const movementWithProps = { ...singleMovement };
                movementWithProps.MOVIMIENTO_NOMBRE_FOR = `${
                  singleMovement.REFERENCIA
                } ${this.formatMoneyString(singleMovement.IMPORTE)}`;
                return movementWithProps;
              }
            ).filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 0
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_CLIENTE &&
                singleMovement.CLAVE_CLIENTE._id ===
                  this.newPayment.CLAVE_CLIENTE &&
                singleMovement.TIPOAFECTACION === "SU" &&
                singleMovement.RESTA > 0
              ) {
                return true;
              }

              return false;
            });
          } else if (this.type === 0) {
            this.selectedAccountMovements = singleBankAccount.MOVIMIENTOS.map(
              (singleMovement) => {
                const movementWithProps = { ...singleMovement };
                movementWithProps.MOVIMIENTO_NOMBRE_FOR = `${
                  singleMovement.REFERENCIA
                } ${this.formatMoneyString(singleMovement.IMPORTE)}`;
                return movementWithProps;
              }
            ).filter((singleMovement) => {
              if (
                singleMovement.STATUS === "CA" ||
                singleMovement.MOSTRAR_TESORERIA === "N"
              ) {
                return false;
              }

              if (
                !singleMovement.CLAVE_CLIENTE &&
                !singleMovement.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 0
              ) {
                return true;
              }

              if (
                singleMovement.CLAVE_PRV &&
                singleMovement.CLAVE_PRV._id === this.newPayment.CLAVE_PRV &&
                singleMovement.TIPOAFECTACION === "RE" &&
                singleMovement.RESTA > 0
              ) {
                return true;
              }

              return false;
            });
          }
        }
      }
    },
    handleMovementSelected() {
      if (this.newPayment.CLAVE_MOVIMIENTO) {
        this.newPayment.affectBalance = false;
        this.newPayment.createMovement = false;

        for (const singleMovement of this.selectedAccountMovements) {
          if (singleMovement._id === this.newPayment.CLAVE_MOVIMIENTO) {
            this.selectedAccountMovementBalance = singleMovement.RESTA;
          }
        }
      }
    },
    validateBankMovement() {
      if (this.newPayment.CLAVE_MOVIMIENTO) {
        return this.selectedAccountMovementBalance >= this.newPayment.PAGO_PARC;
      }
      return true;
    },
    autoCompleteMovement(value) {
      if (!this.selectedAccountMovements) {
        return [];
      }
      return this.selectedAccountMovements.filter((singleMovement) => {
        return (
          singleMovement.IMPORTE &&
          singleMovement.IMPORTE.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    formatMoneyString(val) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(val));
    },
    openSearchMovement() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectMovement,
        props: {
          accountType: this.type === 0 ? "CP" : "CC",
          bankAccount: this.newPayment.CUENTA,
          paymentInformation: this.newPayment,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    lastPaymentNumber() {
      return this.$store.getters.LASTPAYMENTNUMBER;
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },
  },
  watch: {
    companyInformation() {},
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
